





























































































import Vue from "vue";

export default Vue.extend({
  name: "np-editor",
  data: () => ({
    total_items: 0,
    finished_items: 0,
    row_id: 0,
    row_data: {},
    row_status: "geen",
    error: undefined,
    file_is_fixed: false,
    npActive: false,
    nietNpActive: false,
  }),
  methods: {
    nextNp() {
      this.$request(
        `${this.$store.state.APIurl}/beheer/np-check/definitive/get_next_np`
      )
        .then((response: any) => {
          this.npActive = false
          this.nietNpActive = false
          this.row_data = response.data.row_data;
          this.row_id = response.data.row_index;
          this.row_status = "geen";
          this.finished_items = response.data.np_edited_count;
          this.total_items = response.data.np_total_count;
        })
        .catch((error: any) => {
          this.$data.error = error.response.data.detail;
        });
    },
    setNp(isNp: boolean) {
      this.npActive = isNp
      this.nietNpActive = !isNp
      if (isNp) {
        this.row_status = "Natuurlijk persoon";
      } else {
        this.row_status = "Niet-NP";
      }
    },
    postNp(){
      this.$request
        .post(
          `${this.$store.state.APIurl}/beheer/np-check/definitive/set_np?index=${this.row_id}&is_np=${this.npActive}`
        )
        .then((response: any) => {
          console.log(response);
          this.npActive = false
          this.nietNpActive = false
          this.row_data = response.data.row_data;
          this.row_id = response.data.row_index;
          this.row_status = "geen";
          this.finished_items = response.data.np_edited_count;
          this.total_items = response.data.np_total_count;
        })
        .catch((error: any) => {
          this.$data.error = error.response.data.detail;
        });
    },
    finishNP() {
      this.$request
        .post(`${this.$store.state.APIurl}/beheer/np-check/definitive/fix`)
        .then((response: any) => {
          console.log(response);
          this.file_is_fixed = true;
        })
        .catch((error: any) => {
          this.$data.error = error.response.data.detail;
        });
    },
    isFinished() {
      this.$request(
        `${this.$store.state.APIurl}/beheer/np-check/definitive/is_fixed`
      )
        .then((response: any) => {
          this.file_is_fixed = response.data;
        })
        .catch((error: any) => {
          console.log(error);
        });
    },
    handleShortcut(event: any) {
      if (event.key === "j") {
        this.nietNpActive = false
        this.npActive = true
        this.setNp(true);
      } else if (event.key === "n") {
        this.npActive = false
        this.nietNpActive = true
        this.setNp(false);
        // enter or tab
      } else if (event.key === "Enter" || event.key === "Tab") {
        event.preventDefault();
        if (this.row_status != "geen") {
          this.postNp();
        }
      }
    },
  },
  created() {
    this.nextNp();
    this.isFinished();
    window.addEventListener("keydown", this.handleShortcut);
  },
  computed: {
    finished() {
      return this.total_items <= this.finished_items;
    },
  },
  watch: {
    finished(newVal) {
      if (newVal) {
        window.removeEventListener("keydown", this.handleShortcut);
      }
    },
  },
});
