var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('progress-loading',{attrs:{"loading":_vm.loading,"overlay":""}}),_c('v-data-table',{staticClass:"elevation-2 pa-6",attrs:{"headers":_vm.headersForDisplay,"items":_vm.tableDataForDisplay,"footer-props":{ 'items-per-page-options': [20, 25, 50, -1] },"hide-default-footer":!_vm.anyButtonEnabled},scopedSlots:_vm._u([(_vm.anyButtonEnabled)?{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.tableTitle))]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"1000px"},on:{"click:outside":function($event){return _vm.closeDialog()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeDialog()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.enableCreateButton)?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.createItem()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',[_vm._v("Toevoegen")])],1):_vm._e()]}}],null,false,3819307951),model:{value:(_vm.formDialog['show']),callback:function ($$v) {_vm.$set(_vm.formDialog, 'show', $$v)},expression:"formDialog['show']"}},_vm._l((_vm.formComponentsNames),function(formComponent){return _c('span',{key:formComponent},[(_vm.formDialog[formComponent])?_c(formComponent,{tag:"component",attrs:{"item-id":_vm.itemId},on:{"success":function($event){return _vm.$emit('success', $event)},"error":function($event){return _vm.$emit('error', $event)},"close-dialog":function($event){return _vm.closeDialog()}}}):_vm._e()],1)}),0)],1)]},proxy:true}:null,_vm._l((_vm.headersForDisplay),function(col){return {key:("item." + (col.value)),fn:function(ref){
var item = ref.item;
return [(item[col.value] === true)?_c('v-icon',{key:col.value,staticClass:"mt-1",attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")]):(item[col.value] === false)?_c('v-icon',{key:col.value,staticClass:"mt-1",attrs:{"color":"grey"}},[_vm._v(" mdi-close-circle ")]):(item[col.value] === '')?_c('v-icon',{key:col.value,staticClass:"mt-1",attrs:{"color":"grey"}},[_vm._v(" mdi-minus ")]):_c('span',{key:col.value},[_vm._v(" "+_vm._s(item[col.value])+" ")]),_vm._l((_vm.getLabelsForHeader(col.value)),function(label,labelIdx){return [(_vm.isLabelForItem(label, _vm.tableDataForDisplay.indexOf(item)))?_c('v-chip',{key:labelIdx,staticClass:"ml-4 font-weight-bold",attrs:{"color":_vm.labelColors[labelIdx],"text-color":"white","small":""}},[_vm._v(" "+_vm._s(label)+" ")]):_vm._e()]})]}}}),(_vm.anyButtonEnabled)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"float-right",staticStyle:{"max-width":"170px"}},[_c('v-hover',{staticClass:"actionButtons",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.enableDownloadButton),expression:"enableDownloadButton"}],staticClass:"mr-2 my-0 my-1",attrs:{"min-width":"37px","small":"","dark":"","elevation":"0","outlined":!hover,"color":"blue"},on:{"click":function($event){return _vm.itemDownload(item)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-download-circle")]),_c('strong',[_vm._v("Download")])],1)]}}],null,true)}),_c('v-hover',{staticClass:"actionButtons",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.enableEditButton),expression:"enableEditButton"}],staticClass:"mr-2 my-0 mt-1",attrs:{"min-width":"37px","small":"","dark":"","elevation":"0","outlined":!hover,"color":"blue darken"},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-pencil")]),_c('strong',[_vm._v("Bewerken")])],1)]}}],null,true)}),_c('v-hover',{staticClass:"actionButtons",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.enableMigrateButton),expression:"enableMigrateButton"}],staticClass:"mr-2 my-0 mt-1",attrs:{"min-width":"37px","small":"","dark":"","elevation":"0","outlined":!hover,"color":"blue darken"},on:{"click":function($event){return _vm.migrateItem(item)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-swap-horizontal")]),_c('strong',[_vm._v("Migreren")])],1)]}}],null,true)}),_c('v-hover',{staticClass:"actionButtons",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.enableVaststelButton),expression:"enableVaststelButton"}],staticClass:"mr-2 my-1",attrs:{"min-width":"37px","small":"","dark":"","elevation":"0","outlined":!hover,"color":"blue darken"},on:{"click":function($event){_vm.askConfirmation(_vm.itemVaststellen, item, ("U stelt nu " + (_vm.tableTitle.toLowerCase()) + " van " + (item['Geüpload door']) + " vast"))}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-file-lock")]),_c('strong',[_vm._v("Vaststellen")])],1)]}}],null,true)}),_c('v-hover',{staticClass:"actionButtons",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.enableForcedFixButton),expression:"enableForcedFixButton"}],staticClass:"mr-2 my-0 my-1",attrs:{"min-width":"37px","small":"","dark":"","elevation":"0","outlined":!hover,"color":"error"},on:{"click":function($event){_vm.askConfirmation(
              _vm.itemVaststellen,
              item,
              ("U forceert nu de vaststelling van  de " + (_vm.tableTitle.toLowerCase()) + " van " + (item['Geüpload door'] ? item['Geüpload door'] : item['Email'])),
              _vm.danger=true
            )}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-file-lock")]),_c('strong',[_vm._v("Vaststellen")])],1)]}}],null,true)}),_c('v-hover',{staticClass:"actionButtons",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.enableTerugzetButton),expression:"enableTerugzetButton"}],staticClass:"mr-2 my-0 my-1",attrs:{"min-width":"37px","small":"","dark":"","elevation":"0","outlined":!hover,"color":"orange"},on:{"click":function($event){_vm.askConfirmation(_vm.itemTerugzetten, item, ("U draait nu de vastsgestelde " + (_vm.tableTitle.toLowerCase()) + " van " + (item['Geüpload door']) + " terug"))}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-lock-reset")]),_c('strong',[_vm._v("Terugdraaien")])],1)]}}],null,true)}),_c('v-hover',{staticClass:"actionButtons",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.enableDeleteButton),expression:"enableDeleteButton"}],staticClass:"mr-2 my-0 my-1",attrs:{"min-width":"37px","small":"","dark":"","elevation":"0","outlined":!hover,"color":"error"},on:{"click":function($event){_vm.askConfirmation(_vm.deleteItem, item, ("U verwijdert nu " + (_vm.tableTitle.toLowerCase()) + " van " + (item['Geüpload door'] ? item['Geüpload door'] : item['Email'])))}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-trash-can-outline")]),_c('strong',[_vm._v("Verwijderen")])],1)]}}],null,true)}),_c('v-hover',{staticClass:"actionButtons",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.enableArchiveButton),expression:"enableArchiveButton"}],staticClass:"mr-2 my-1",attrs:{"min-width":"37px","small":"","dark":"","elevation":"0","outlined":!hover,"color":"error"},on:{"click":function($event){return _vm.archiveItem(item)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-archive")]),_c('strong',[_vm._v("Archiveren")])],1)]}}],null,true)}),_c('v-hover',{staticClass:"actionButtons",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.enableImpersonateButton),expression:"enableImpersonateButton"}],staticClass:"mr-2 my-1",attrs:{"min-width":"37px","small":"","dark":"","elevation":"0","outlined":!hover,"color":"error"},on:{"click":function($event){return _vm.askConfirmation(_vm.impersonateItem, item, ("U neemt nu gebruiker " + (item.Email) + " over"))}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-account-switch")]),_c('strong',[_vm._v("Overnemen")])],1)]}}],null,true)})],1)]}}:null],null,true)}),_c('v-dialog',{attrs:{"width":"300"},model:{value:(_vm.confirm['show']),callback:function ($$v) {_vm.$set(_vm.confirm, 'show', $$v)},expression:"confirm['show']"}},[_c('v-card',[(_vm.confirm['danger'])?_c('v-card-title',{staticClass:"error white--text"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("mdi-alert")]),_vm._v(" Geforceerde actie ")],1):_vm._e(),_c('v-card-text',{staticClass:"pa-4"},[_c('p',{staticClass:"pa-0 mb-1"},[_c('strong',[_vm._v("Let op!")])]),_c('p',{staticClass:"pa-0 pt-2 mb-1"},[_vm._v(_vm._s(_vm.confirm['msg']))])]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{staticClass:"blue two-btns",attrs:{"text":""},on:{"click":function($event){return _vm.confirmConfirmation()}}},[_vm._v("Ga door")]),_c('v-spacer'),_c('v-btn',{staticClass:"red two-btns",attrs:{"text":""},on:{"click":_vm.cancelConfirmation}},[_vm._v("Annuleer")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }